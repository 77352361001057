<template>
  <main>
    <div class="container">
      <form class="form-horizontal mb20">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  送信完了<span>complete</span>
                </h2>
              </div>
              <div class="panel-body">
                <div class="row mb10">
                  <div class="col-sm-12 center pt40 pb40">
                    <p>ご指定のアドレスにパスワード再設定URLをお送り致しました。</p>
                    <p>アクセス後、変更をお願いいたします。</p>
                  </div>
                </div>
                <ul class="actionVertical clearfix">
                  <li>
                    <a href="/login" class="btn btn01">
                      ウィンドウを閉じる
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: 'MailSendResetPass',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
